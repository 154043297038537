import Swal from 'sweetalert2';
import { firebase, googleAuthProvider } from "../firebase/firebase-config";
import { types } from "../types/types"
import { noteLogout } from './notes';
import { finishLoading, startLoading } from "./ui";


export const startLoginEmailPassword = ( email, password ) => {
    return ( dispatch ) => {

        dispatch (
            startLoading ( )
        );

       // anexamos return para en cadenar hacia afuera el retorno de firebase
        return firebase.auth().signInWithEmailAndPassword( email, password )
        .then(  ({ user }) => {
            //console.log (user);
            

            dispatch (
                login ( user.uid, user.displayName )
            )

            dispatch (
                finishLoading ( )
            )

        })
        .catch ( e => {
            //console.log (e);
            dispatch (
                finishLoading ( )
            )

            Swal.fire('Error', e.message, 'error' );
        })
        ; 
        // setTimeout ( () => {
        //     dispatch ( login (123, 'Pedro') );
        // }, 3500);
    }
}

export const startGoogleLogin = () => {
    return ( dispatch ) => {
        firebase.auth().signInWithPopup ( googleAuthProvider )
        .then( ({ user }) => {
            dispatch (
                login ( user.uid, user.displayName )
            )
        }); 
    }
}

export const startRegisterWithEmailPasswordName = ( email, password, name ) => {
    return ( dispatch ) => {
        firebase.auth().createUserWithEmailAndPassword ( email, password )
        .then( async ({ user }) => {

            await user.updateProfile ( { displayName: name});
            //console.log (user);
            dispatch (
                login ( user.uid, user.displayName )
            )
        })
        .catch ( e => {
            //console.log (e);
            //console.log( e.message );
            Swal.fire('Error', e.message, 'error' );
            return e;
        })
        ; 
    }
}


// export const login  = ( uid, displayName ) => {
//     return {
//         types: types.login,
//         payload: {
//             uid,
//             displayName
//         }
//     }
// }

export const login  = ( uid, displayName ) => ({
        type: types.login,
        payload: {
            uid,
            displayName
        }
    
});


export const startLogout = () => {
    return async ( dispatch ) => {
        await firebase.auth().signOut();

        dispatch ( logout ());

        //Aqui purgar info del usuario
        dispatch ( noteLogout());


    }
}

export const logout = () => ({
    type: types.logout
})
